<template>
    <div>
        <v-row no-gutters>
            <v-col xs="12" md="6" lg="4">
                <div class="text-center">
                    <img :src="require('../assets/images/checkout-coat.svg')" style="height:150px;" />
                </div>
                <div class="subheading text-center font-weight-light">
                    <v-row no-gutters>
                        <v-col cols="3" class="px-1">
                            <v-text-field v-model="coatSize" label="Size" :error-messages="coatSizeError" @keydown="intOnly($event)"></v-text-field>
                        </v-col>
                        <v-col cols="5" class="px-1">
                            <v-select v-model="coatLength" :items="coatLengthOptions" item-text="name" item-value="id"
                                label="Length" :error-messages="coatLengthError"></v-select>
                        </v-col>
                        <v-col cols="3" class="px-1">
                            <v-text-field v-model="coatSleeve" label="Sleeve" :error-messages="coatSleeveError" @keydown="numbersOnly($event)"></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col xs="12" md="6" lg="4">
                <div class="text-center">
                    <img :src="require('../assets/images/checkout-vest.svg')" style="height:150px;" />
                </div>
                <div class="subheading text-center font-weight-light">
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col>
                            <v-select v-model="vestSize" :items="vestSizeOptions" label="Size" :error-messages="vestSizeError"></v-select>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col xs="12" md="6" lg="4">
                <div class="text-center">
                    <img :src="require('../assets/images/checkout-pants.svg')" style="height:150px;" />
                </div>
                <div class="subheading text-center font-weight-light">
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col cols="3" class="px-1">
                            <v-text-field v-model="waist" label="Waist" :error-messages="waistError" @keydown="numbersOnly($event)"></v-text-field>
                        </v-col>
                        <v-col cols="3" class="px-1">
                            <v-select v-model="pantType" :items="pantTypeOptions" label="Type" :error-messages="pantTypeError"></v-select>
                        </v-col>
                        <v-col cols="4" class="px-1">
                            <v-text-field v-model="outseam" label="Outseam" :error-messages="outseamError" @keydown="numbersOnly($event)"></v-text-field>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col xs="12" md="6" lg="4">
                <div class="text-center">
                    <img :src="require('../assets/images/checkout-shirt.svg')" style="height:150px;" />
                </div>
                <div class="subheading text-center font-weight-light">
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col cols="10">
                            <v-select v-model="shirtSize" :items="shirtSizeOptions" label="Size" :error-messages="shirtSizeError"></v-select>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col xs="12" md="6" lg="4">
                <div class="text-center">
                    <img :src="require('../assets/images/checkout-shoes.svg')" style="height:150px;" />
                </div>
                <div class="subheading text-center font-weight-light">
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col xs="3" class="px-1">
                            <v-text-field v-model="shoeSize" label="Size" :error-messages="shoeSizeError" @keydown="numbersOnly($event)"></v-text-field>
                        </v-col>
                        <v-col xs="3" class="px-1">
                            <v-select v-model="shoeType" :items="shoeTypeOptions" label="Type" :error-messages="shoeTypeError"></v-select>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col xs="12" md="6" lg="4">
                <div class="subheading text-center font-weight-light">
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col cols="10">
                            <v-row flex-column>
                                <v-select v-model="tieSize" :items="accesoryOptions" label="Neckwear Size" :error-messages="tieSizeError"></v-select>
                                <v-select v-model="cummerbundSize" :items="accesoryOptions" label="Cummerbund Size" :error-messages="cummerbundSizeError"></v-select>
                            </v-row>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { _st } from '@/softech';
export default {
    props: {
        measurements                : { type: [Object, Array], default: function() { return {} } },
        measuresRequired            : { type: Boolean, default: true },
        showPreviousMeasurements    : { type: Boolean, default: true }
    },
    data: function() {
        return {
            coatSize            : '',
            coatSizeError       : '',
            coatLength          : '',
            coatLengthError     : '',
            coatSleeve          : '',
            coatSleeveError     : '',
            vestSize            : '',
            vestSizeError       : '',
            waist               : '',
            waistError          : '',
            outseam             : '',
            outseamError        : '',
            pantType            : '',
            pantTypeError       : '',
            shirtSize           : '',
            shirtSizeError      : '',
            shoeSize            : '',
            shoeSizeError       : '',
            shoeType            : '',
            shoeTypeError       : '',
            tieSize             : '',
            tieSizeError        : '',
            bowtieSize          : '',
            bowtieSizeError     : '',
            cummerbundSize      : '',
            cummerbundSizeError : '',
            coatLengthOptions   : [
                { id: 'B', name: 'Boy' },
                { id: 'S', name: 'Short' },
                { id: 'R', name: 'Regular' },
                { id: 'L', name: 'Long' }
            ],
            vestSizeOptions     : [ 'B', 'BXS', 'BS', 'BM', 'BL', 'XS', 'S', 'M', 'L', '2X', 'XL', '2XL', '3X', '3XL', '4XL', '5XL', 'FA' ],
            pantTypeOptions     : [ 'B', 'R' ],
            shirtSizeOptions    : [
                'BXS','BS','BM','BL','BL0','XS','XS1','XS3','XS5','S','S1','S3','S5','S7','M','M1','M3','M5','M7','M9','L','L1','L3','L5','L7','L9','XL','XL1',
                'XL3','XL5','XL7','XL9','2X','2XL','2X3','2X5','2X7','2X9','3XL','3X3','3X5','3X7','3X9','4X3','4X5','4X7','5XL','5X3',
                '5X5','5X7','5X9'
            ],
            shoeTypeOptions     : [ 'B', 'W' ],
            accesoryOptions     : [ 'B', 'BS', 'BM', 'BL', 'XS', 'S', 'M', 'L', 'XL','2X','2XL','3X', 'FA' ]
        }
    },
    watch: {
        measurements( v ) {
            if ( v == null || v == undefined )
                return;

            this.coatSize = v.coatSize;
            this.coatLength = v.coatLength;
            this.coatSleeve = v.coatSleeve;
            this.vestSize = v.vestSize;
            this.waist = v.pantWaist;
            this.outseam = v.pantOutseam;
            this.pantType = v.pantType;
            this.shirtSize = v.shirtSize;
            this.shoeSize = v.shoeSize;
            this.shoeType = v.shoeType;
            this.tieSize = v.tieSize;
            this.bowtieSize = v.bowtieSize;
            this.cummerbundSize = v.cummerbundSize;
        },
    },
    computed: {
        rentalMeasurements() {
            if( !this.isValid() )
                return null;

            return {
                coatSize: this.coatSize,
                coatLength: this.coatLength,
                coatSleeve: this.coatSleeve,
                vestSize: this.vestSize,
                shirtSize: this.shirtSize,
                pantWaist: this.waist,
                pantOutseam: this.outseam,
                pantType: this.pantType,
                shoeSize: this.shoeSize,
                shoeType: this.shoeType,
                tieSize: this.tieSize,
                bowtieSize: this.bowtieSize,
                cummerbundSize: this.cummerbundSize
            }
        }
    },
    mounted() {
        if( !_st.isNU( this.measurements ) && !_st.isNU( this.measurements.id ) ) {
            this.coatSize = this.measurements.coatSize;
            this.coatLength = this.measurements.coatLength;
            this.coatSleeve = this.measurements.coatSleeve;
            this.vestSize = this.measurements.vestSize;
            this.waist = this.measurements.pantWaist;
            this.outseam = this.measurements.pantOutseam;
            this.pantType = this.measurements.pantType;
            this.shirtSize = this.measurements.shirtSize;
            this.shoeSize = this.measurements.shoeSize;
            this.shoeType = this.measurements.shoeType;
            this.tieSize = this.measurements.tieSize;
            this.bowtieSize = this.measurements.bowtieSize;
            this.cummerbundSize = this.measurements.cummerbundSize;
        }

    },
    methods: {
        prepareMeasurements() {
            if( !this.isValid() )
                return;

            let m = {
                coatSize: this.coatSize,
                coatLength: this.coatLength,
                coatSleeve: this.coatSleeve,
                vestSize: this.vestSize,
                waist: this.waist,
                outseam: this.outseam,
                pantType: this.pantType,
                shirtSize: this.shirtSize,
                shoeSize: this.shoeSize,
                shoeType: this.shoeType,
                tieSize: this.tieSize,
                bowtieSize: this.bowtieSize,
                cummerbundSize: this.cummerbundSize
            };

            this.$emit('onMeasurementsReady', m);
        },
        resetMeasurements() {
            if( this.showPreviousMeasurements )
                return;

            this.coatSize = '';
            this.coatLength = '';
            this.coatSleeve = '';
            this.vestSize = '';
            this.waist = '';
            this.outseam = '';
            this.pantType = '';
            this.shirtSize = '';
            this.shoeSize = '';
            this.shoeType = '';
            this.tieSize = '';
            this.bowtieSize = '';
            this.cummerbundSize = '';
            this.coatSizeError = '';
            this.coatLengthError = '';
            this.coatSleeveError = '';
            this.vestSizeError = '';
            this.waistError = '';
            this.outseamError = '';
            this.pantTypeError = '';
            this.shirtSizeError = '';
            this.shoeSizeError = '';
            this.shoeTypeError = '';
            this.tieSizeError = '';
            this.bowtieSizeError = '';
            this.cummerbundSizeError = '';
            this.$emit('onMeasurementsCleaned');
        },
        isValid() {
            return this.validate(this.measuresRequired);
        },
        validate( areRequired ) {
            let errorFound = false;
            this.coatSizeError = this.coatLengthError = this.coatSleeveError = 
            this.waistError = this.outseamError = this.shirtSizeError = 
            this.shoeSizeError = this.vestSizeError = this.pantTypeError = 
            this.shoeTypeError = this.tieSizeError = this.bowtieSizeError = 
            this.cummerbundSizeError = '';

            let showMessage = this.measuresRequired || this.coatSize.length > 0 || this.coatLength.length > 0 || 
                           this.coatSleeve.length > 0 || this.vestSize.length > 0 || this.waist.length > 0 || this.outseam.length > 0 ||
                           this.pantType.length > 0 || this.shirtSize.length > 0 || this.shoeSize.length > 0 || this.shoeType.length > 0 ||
                           this.tieSize.length > 0 || this.bowtieSize.length > 0 || this.cummerbundSize.length > 0;
            let required = areRequired || this.coatSize.length > 0 || this.coatLength.length > 0 || 
                           this.coatSleeve.length > 0 || this.vestSize.length > 0 || this.waist.length > 0 || this.outseam.length > 0 ||
                           this.pantType.length > 0 || this.shirtSize.length > 0 || this.shoeSize.length > 0 || this.shoeType.length > 0 ||
                           this.tieSize.length > 0 || this.bowtieSize.length > 0 || this.cummerbundSize.length > 0;

            if( required && this.coatSize.length == 0) {
                errorFound = true;
                this.coatSizeError = showMessage ? 'required' : '';
            }
            if( required && this.coatLength.length == 0) {
                errorFound = true;
                this.coatLengthError = showMessage ? 'required' : '';
            }
            if( required && this.coatSleeve.length == 0) {
                errorFound = true;
                this.coatSleeveError = showMessage ? 'required' : '';
            }
            // if( required && this.vestSize.length == 0) {
            //     errorFound = true;
            //     this.vestSizeError = showMessage ? 'required' : '';
            // }
            if( required && this.waist.length == 0) {
                errorFound = true;
                this.waistError = showMessage ? 'required' : '';
            }
            else if ( required && !/[0-9]+|[0-9]+[LR]{1}/g.test(this.waist) ){
                errorFound = true;
                this.waistError = showMessage ? 'invalid' : '';
            }
            if( required && this.outseam.length == 0) {
                errorFound = true;
                this.outseamError = showMessage ? 'required' : '';
            }
            if( required && this.pantType.length == 0) {
                errorFound = true;
                this.pantTypeError = showMessage ? 'required' : '';
            }
            if( required && this.shirtSize.length == 0) {
                errorFound = true;
                this.shirtSizeError = showMessage ? 'required' : '';
            }
            else if ( required && !/[A-Z]{1}[0-9]+|[A-Z]{1,2}[0-9]*|[0-9]{1}[A-Z]+[0-9]*/g.test(this.shirtSize) ){
                errorFound = true;
                this.shirtSizeError = showMessage ? 'invalid' : '';
            }
            // if( required && this.shoeSize.length == 0) {
            //     errorFound = true;
            //     this.shoeSizeError = showMessage ? 'required' : '';
            // }
            // else 
            if ( this.shoeSize.length > 0 && !/[0-9]+|[0-9]+[A-Z]{1}/g.test(this.shoeSize) ){
                errorFound = true;
                this.shoeSizeError = showMessage ? 'invalid' : '';
            }
            if ( this.shoeSize.length > 0 && this.shoeType.length == 0 ) {
                errorFound = true;
                this.shoeTypeError = showMessage ? 'required' : '';
            }
            // if ( required && this.tieSize.length == 0 ){
            //     errorFound = true;
            //     this.tieSizeError = showMessage ? 'required' : '';
            // }
            // if ( required && this.bowtieSize.length == 0 ){
            //     errorFound = true;
            //     this.bowtieSizeError = showMessage ? 'required' : '';
            // }
            // if ( required && this.cummerbundSize.length == 0 ){
            //     errorFound = true;
            //     this.cummerbundSizeError = showMessage ? 'required' : '';
            // }

            return !errorFound;
        },
        save(customerId) {
            if( _st.isNUE( customerId ) )
                return false;

            if( !this.validate(true) )
                return false;
            
            let axiosOptions = {
                method: 'POST',
                url: '/customer/rental-measurement',
                data: {
                    identifier: customerId,
                    measurements: {
                        coat_size: this.rentalMeasurements.coatSize,
                        coat_length: this.rentalMeasurements.coatLength,
                        coat_sleeve: this.rentalMeasurements.coatSleeve,
                        vest_size: this.rentalMeasurements.vestSize,
                        waist: this.rentalMeasurements.pantWaist,
                        outseam: this.rentalMeasurements.pantOutseam,
                        pant_type: this.rentalMeasurements.pantType,
                        shirt_size: this.rentalMeasurements.shirtSize,
                        shoe_size: this.rentalMeasurements.shoeSize,
                        shoe_type: this.rentalMeasurements.shoeType,
                        tie_size: this.rentalMeasurements.tieSize,
                        bowtie_size: this.rentalMeasurements.bowtieSize,
                        cummerbund_size: this.rentalMeasurements.cummerbundSize,
                    }
                },
                config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' } }
            };

            this.$http(axiosOptions)
            .then( response => { 
                if( response.status === 401 ) {
                    let obj = this;
                    this.$root.$el.__vue__.$once('login', function() {
                        obj.save(customerId);
                    });
                    return;
                }
                
                this.saving = false;
                if(!response.data.status && response.data.error) {
                    this.$emit('error', response.data.message);
                    return false;
                }

                if(!response.data.status && !response.data.error) 
                    this.$emit('error', 'Error saving');

                if( response.data.status ) {
                    this.$emit('saved', response.data.measurements);
                    return true;
                }
            })
            .catch(error => {
                this.$emit('error', error);
                console.log(error);
            });
        },
        load(customerId) {
            if( customerId == null || customerId == undefined || customerId.length == 0 )
                return false;

            this.$http
            .get( '/customer/rental-measurements/' + customerId ) 
            .then( response => { 
                if( response.status === 401 ) {
                    let obj = this;
                    this.$root.$el.__vue__.$once('login', function() {
                        obj.load(customerId);
                    });
                    return;
                }

                if(!response.data.status && response.data.error) {
                    this.$emit('error', response.data.message);
                    return false;
                }

                if(!response.data.status && !response.data.error) 
                    this.$emit('error', 'Error loading');

                this.coatSize = response.data.data.coatSize != null ? response.data.data.coatSize : '';
                this.coatLength = response.data.data.coatLength != null ? response.data.data.coatLength : '';
                this.coatSleeve = response.data.data.coatSleeve != null ? response.data.data.coatSleeve : '';
                this.vestSize = response.data.data.vestSize != null ? response.data.data.vestSize : '';
                this.waist = response.data.data.pantWaist != null ? response.data.data.pantWaist : '';
                this.outseam = response.data.data.pantOutseam != null ? response.data.data.pantOutseam : '';
                this.pantType = response.data.data.pantType != null ? response.data.data.pantType : '';
                this.shirtSize = response.data.data.shirtSize != null ? response.data.data.shirtSize : '';
                this.shoeSize = response.data.data.shoeSize != null ? response.data.data.shoeSize : '';
                this.shoeType = response.data.data.shoeType != null ? response.data.data.shoeType : '';
                this.tieSize = response.data.data.tieSize != null ? response.data.data.tieSize : '';
                this.bowtieSize = response.data.data.bowtieSize != null ? response.data.data.bowtieSize : '';
                this.cummerbundSize = response.data.data.cummerbundSize != null ? response.data.data.cummerbundSize : '';
            })
            .catch((error) => {
                this.$emit('error', error);
                console.log(error);
            });
        },
        loadMeasurements(m) {
            if( _st.isNU( m ) )
                return;

            this.coatSize = m.coatSize != null ? m.coatSize : '';
            this.coatLength = m.coatLength != null ? m.coatLength : '';
            this.coatSleeve = m.coatSleeve != null ? m.coatSleeve : '';
            this.vestSize = m.vestSize != null ? m.vestSize : '';
            this.waist = m.pantWaist != null ? m.pantWaist : '';
            this.outseam = m.pantOutseam != null ? m.pantOutseam : '';
            this.pantType = m.pantType != null ? m.pantType : '';
            this.shirtSize = m.shirtSize != null ? m.shirtSize : '';
            this.shoeSize = m.shoeSize != null ? m.shoeSize : '';
            this.shoeType = m.shoeType != null ? m.shoeType : '';
            this.tieSize = m.tieSize != null ? m.tieSize : '';
            this.bowtieSize = m.bowtieSize != null ? m.bowtieSize : '';
            this.cummerbundSize = m.cummerbundSize != null ? m.cummerbundSize : '';
        }
    },
}
</script>

<style>
    
</style>